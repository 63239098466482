<template>
  <form
    autocomplete="off"
    @submit.prevent
  >
    <float-label>
      <ag-autocomplete
        :autofocus="autofocus"
        :placeholder="placeholder"
        :options="suggestionsOptions"
        :processing="isLoadingSuggestions"
        @cleared="clearAddress"
        @onChosen="onChosen"
        @onFocus="GetAwsToken"
        @onInput="autocomplete"
        @onSearch="onSearch"
      >
        <template #action-append>
          <b-icon-trash
            @click="deleteInput"
            class="mx-2 delete-icon"
            title="Remove this address"
            v-if="isDeleteAllowed"
          />
        </template>
      </ag-autocomplete>
    </float-label>
  </form>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import addressSearch from '@/mixins/addressSearch';

import EventBus from '@/eventBus';

import AgAutocomplete from '@afrigis/afrigis-vue-autocomplete';
import FloatLabel from 'vue-float-label/components/FloatLabel.vue';

import {
  BIconTrash,
} from 'bootstrap-vue';

export default {
  components: {
    AgAutocomplete,
    BIconTrash,
    FloatLabel,
  },
  computed: {
    ...mapGetters({
      SelectedAvoidOption: 'journeyStore/SelectedAvoidOption',
      SelectedOptimisationParam: 'journeyStore/SelectedOptimisationParam',
      WaypointInputs: 'journeyStore/WaypointInputs',
      ValidWaypoints: 'journeyStore/ValidWaypoints',
    }),
    selectedDetailResults() {
      return this.DetailedResults.find((i) => i.id === this.inputId)?.result;
    },
    isLoadingSuggestions() {
      return !!this.LoadingSuggestions.find((id) => id === this.inputId);
    },
    suggestionsOptions() {
      return this.Suggestions.find((i) => i.id === this.inputId)?.result;
    },
  },
  name: 'WaypointInput',
  methods: {
    ...mapActions({
      GetAwsToken: 'addressSearchStore/fetchAwsCredentials',
      GetRoute: 'journeyStore/GetRoute',
    }),
    ...mapMutations({
      SetWaypointInputs: 'journeyStore/SetWaypointInputs',
    }),
    clearAddress() {
      const index = this.WaypointInputs.findIndex((i) => i.id === this.inputId);
      const localWaypoints = this.WaypointInputs;
      localWaypoints[index].latitude = null;
      localWaypoints[index].longitude = null;
      this.SetWaypointInputs(localWaypoints);
      EventBus.$emit('clear-map');
    },
    deleteInput() {
      const localWaypoints = this.WaypointInputs.filter((i) => i.id !== this.inputId);
      this.SetWaypointInputs(localWaypoints);
      EventBus.$emit('clear-map');
    },
    onChosen(data) {
      this.doSearchDetail({
        id: this.inputId,
        seoid: data.seoid,
      });
      const newWaypoints = this.WaypointInputs
        .map((w) => (w.id === this.inputId ? { ...w, description: data.description } : w));
      this.SetWaypointInputs(newWaypoints);
    },
    autocomplete(searchText) {
      if (searchText.length < 3) {
        return;
      }
      this.LastEnteredText(searchText);
      if (this.autocompleteCharacterTimer) {
        clearTimeout(this.autocompleteCharacterTimer);
      }
      this.autocompleteCharacterTimer = setTimeout(() => {
        this.searchError = null;
        this.doAutocomplete({ id: this.inputId, searchText })
          .catch(() => {
            this.searchError = 'Could not Autocomplete';
          });
      }, 300);
    },
    onSearch(searchText) {
      this.doSearch({
        id: this.inputId,
        searchText,
      });
    },
  },
  mixins: [addressSearch],
  props: {
    autofocus: {
      default: false,
      type: Boolean,
    },
    inputId: {
      required: true,
      type: String,
    },
    isDeleteAllowed: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  watch: {
    selectedDetailResults(newV) {
      if (!newV) {
        return;
      }
      const { location: { lat, lng } } = newV;
      const index = this.WaypointInputs.findIndex((i) => i.id === this.inputId);
      const localWaypoints = this.WaypointInputs;
      localWaypoints[index].latitude = lat;
      localWaypoints[index].longitude = lng;
      this.SetWaypointInputs(localWaypoints);
    },
  },
};
</script>

<style>
.delete-icon {
  cursor: pointer;
  margin-top:10px;
}
</style>
