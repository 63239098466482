<template>
  <b-list-group>
    <b-list-group-item
      class="d-flex align-items-start"
      :key="waypoint.id"
      v-for="(waypoint, index) in Waypoints"
    >
      <b-avatar
        :size="30"
        variant="mute"
        :src="imageUrl(index)"/>
      <p class="ml-3">{{ waypoint.description }}</p>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import {
  mapGetters,
} from 'vuex';

import {
  BAvatar,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue';

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    ...mapGetters({
      WaypointInputs: 'journeyStore/WaypointInputs',
      DetailedResults: 'addressSearchStore/detailedResults',
    }),
    Waypoints() {
      return [...this.WaypointInputs].filter((w) => !!w.latitude && !!w.longitude);
    },
  },
  methods: {
    imageUrl(index) {
      if (index === 0) {
        return require('@/assets/icons/origin.png');
      }
      if ((index + 1) === this.Waypoints.length) {
        return require('@/assets/icons/destination.png');
      }
      switch (index) {
        case 1:
          return require('@/assets/icons/waypoints/1.png');
        case 2:
          return require('@/assets/icons/waypoints/2.png');
        case 3:
          return require('@/assets/icons/waypoints/3.png');
        default:
          return require('@/assets/icons/waypoint.png');
      }
    },
  },
  name: 'JourneyOverview',
};
</script>

<style></style>
