import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      Suggestions: 'addressSearchStore/suggestions',
      LoadingSuggestions: 'addressSearchStore/loadingSuggestions',
      DetailedResults: 'addressSearchStore/detailedResults',
      token: 'addressSearchStore/token',
    }),
  },
  data() {
    return {
      autocompleteCharacterTimer: null,
      searchError: null,
    };
  },
  methods: {
    ...mapActions(
      'addressSearchStore',
      ['doAutocomplete', 'doSearch', 'doSearchDetail', 'fetchAwsCredentials', 'searchLastEnteredText'],
    ),
    ...mapMutations(
      'addressSearchStore',
      ['clearDetailedResults', 'clearSuggestions', 'LastEnteredText'],
    ),
  },
};
