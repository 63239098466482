import Axios from 'axios';
import getterUtilities from '@/store/utilities/getters';
import mutationUtilities from '@/store/utilities/mutations';
import { addressDetails, addressSearch } from '@afrigis/aws-search-services';

const STATEVAR_AXIOSCANCELSOURCE = 'autocompleteAxiosCancelSource';
const STATEVAR_DETAILED_RESULTS = 'detailedResults';
const STATEVAR_KEY = 'key';
const STATEVAR_LASTENTEREDTEXT = 'lastEnteredText';
const STATEVAR_LOADING_SUGGESTIONS = 'loadingSuggestions';
const STATEVAR_SUGGESTIONS = 'suggestions';
const STATEVAR_TOKEN = 'token';

const MUTATION_SET_LOADING_SUGGESTIONS = 'loadingSuggestions';
const MUTATION_SET_SUGGESTIONS = 'commitSuggestions';
const MUTATION_CANCEL_AUTOCOMPLETE = 'cancelAutocomplete';
const MUTATION_SET_AXIOS_CANCELSOURCE = 'autocompleteAxiosCancelSource';
const MUTATION_SET_KEY = 'SetKey';
const MUTATION_SET_DETAILS_RESULTS = 'commitDetailedResults';
const MUTATION_SET_TOKEN = 'commitToken';

const ACTION_DOSEARCH = 'doSearch';
const ACTION_SEARCHADDRESS = 'searchForAddressString';

const PROXY_URL = `${process.env.VUE_APP_ROOT_API}/proxies/search/api`;

const state = {
  [STATEVAR_AXIOSCANCELSOURCE]: null,
  [STATEVAR_DETAILED_RESULTS]: [],
  [STATEVAR_KEY]: null,
  [STATEVAR_LASTENTEREDTEXT]: null,
  [STATEVAR_LOADING_SUGGESTIONS]: [],
  [STATEVAR_SUGGESTIONS]: [],
  [STATEVAR_TOKEN]: null,
};

function awsAxiosInstance(token, key) {
  const axiosInstance = Axios.create();
  axiosInstance.interceptors.request
    .use(
      (config) => {
        const authorisationHeader = `Bearer ${token}`;
        const { headers } = config;
        headers.Authorization = authorisationHeader;
        headers.common['x-api-key'] = key;
        headers.common['Access-Control-Allow-Origin'] = '*';
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  return axiosInstance;
}

const actions = {
  doAutocomplete: (context, payload) => new Promise((resolve, reject) => {
    // add id of current input to loading state
    let localLoadingState = context.state[STATEVAR_LOADING_SUGGESTIONS];
    localLoadingState.push(payload.id);
    context.commit(MUTATION_SET_LOADING_SUGGESTIONS, localLoadingState);

    // clear current input suggestions
    let localSuggestionsState = context.state[STATEVAR_SUGGESTIONS]
      .filter((i) => i.id !== payload.id);
    context.commit(MUTATION_SET_SUGGESTIONS, localSuggestionsState);

    context.commit(MUTATION_CANCEL_AUTOCOMPLETE);
    context.commit(MUTATION_SET_AXIOS_CANCELSOURCE, Axios.CancelToken.source());

    const autocompleteUrl = 'https://afrigis.services/places-autocomplete/api/v3/autocomplete';

    const axiosInstance = awsAxiosInstance(
      context.state[STATEVAR_TOKEN],
      context.state[STATEVAR_KEY],
    );

    const encodedSearch = encodeURIComponent(payload.searchText);
    axiosInstance({
      url: `${autocompleteUrl}?query=${encodedSearch}`,
      cancelToken: state.autocompleteAxiosCancelSource.token,
    }).then((result) => {
      const mappedResult = result.data.result.map((r) => ({
        ...r,
        type: 'address',
      }));
      localSuggestionsState = context.state[STATEVAR_SUGGESTIONS];
      localSuggestionsState.push({
        id: payload.id,
        result: mappedResult,
      });
      context.commit(MUTATION_SET_SUGGESTIONS, localSuggestionsState);
      resolve();
    }).catch(() => {
      reject();
    })
      .finally(() => {
        // remove id from loading state
        localLoadingState = context.state[STATEVAR_LOADING_SUGGESTIONS]
          .filter((id) => id !== payload.id);
        context.commit(MUTATION_SET_LOADING_SUGGESTIONS, localLoadingState);
      });
  }),
  doSearch: ({ dispatch }, payload) => new Promise(() => dispatch(ACTION_SEARCHADDRESS, payload)),
  searchLastEnteredText: ({ dispatch }) => new Promise(() => (
    dispatch(ACTION_DOSEARCH, state.lastEnteredText)
  )),
  doSearchDetail: async (context, payload) => {
    const axiosInstance = awsAxiosInstance(
      context.state[STATEVAR_TOKEN],
      context.state[STATEVAR_KEY],
    );
    try {
      const { data: { code, message, result } } = await addressDetails(
        axiosInstance,
        payload.seoid,
      );

      if (code !== 200) {
        throw new Error(message);
      }
      const localDetailsState = context.state[STATEVAR_DETAILED_RESULTS]
        .filter((i) => i.id !== payload.id);
      localDetailsState.push({
        id: payload.id,
        result,
      });
      context.commit(MUTATION_SET_DETAILS_RESULTS, localDetailsState);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    }
  },
  fetchAwsCredentials: (context) => new Promise((resolve, reject) => {
    if (context.state[STATEVAR_TOKEN] && context.state[STATEVAR_KEY]) {
      return;
    }
    const requestObj = {
      url: `${PROXY_URL}/awsauthentication/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    Axios(requestObj)
      .then((response) => {
        const {
          token,
          key,
        } = response.data.result;
        context.commit(MUTATION_SET_TOKEN, token);
        context.commit(MUTATION_SET_KEY, key);
        resolve();
      })
      .catch(() => {
        reject();
      });
  }),
  searchForAddressString: async (context, payload) => {
    if (payload.searchText.length < 2) {
      return;
    }
    let localLoadingState = context.state[STATEVAR_LOADING_SUGGESTIONS];
    localLoadingState.push(payload.id);
    context.commit(MUTATION_SET_LOADING_SUGGESTIONS, localLoadingState);

    // clear current input suggestions
    let localSuggestionsState = context.state[STATEVAR_SUGGESTIONS]
      .filter((i) => i.id !== payload.id);
    context.commit(MUTATION_SET_SUGGESTIONS, localSuggestionsState);

    const axiosInstance = awsAxiosInstance(
      context.state[STATEVAR_TOKEN],
      context.state[STATEVAR_KEY],
    );

    try {
      const { data: { code, message, result } } = await addressSearch(
        axiosInstance,
        payload.searchText,
      );
      if (code !== 200) {
        throw new Error(message);
      }
      const suggestion = result.map((r) => (
        {
          seoid: r.seoid,
          description: r.formatted_address,
          latitude: r.location.lat,
          longitude: r.location.lng,
          type: 'address',
        }
      ));
      localSuggestionsState = context.state[STATEVAR_SUGGESTIONS];
      localSuggestionsState.push({
        id: payload.id,
        result: suggestion,
      });
      context.commit(MUTATION_SET_SUGGESTIONS, localSuggestionsState);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    } finally {
      localLoadingState = context.state[STATEVAR_LOADING_SUGGESTIONS]
        .filter((id) => id !== payload.id);
      context.commit(MUTATION_SET_LOADING_SUGGESTIONS, localLoadingState);
    }
  },
};

const getters = {
  detailedResults: getterUtilities.get(STATEVAR_DETAILED_RESULTS, 'id'),
  key: getterUtilities.get(STATEVAR_KEY),
  loadingSuggestions: getterUtilities.get(STATEVAR_LOADING_SUGGESTIONS),
  suggestions: getterUtilities.get(STATEVAR_SUGGESTIONS),
  token: getterUtilities.get(STATEVAR_TOKEN),
};

const mutations = {
  autocompleteAxiosCancelSource: mutationUtilities.set(STATEVAR_AXIOSCANCELSOURCE),
  cancelAutocomplete: (stateP) => {
    if (stateP.autocompleteAxiosCancelSource) {
      const localState = stateP;
      localState.autocompleteAxiosCancelSource.cancel();
    }
  },
  clearDetailedResults: (stateP) => {
    const localState = stateP;
    localState.detailedResults = null;
  },
  clearSuggestions: (stateP) => {
    const localState = stateP;
    localState.suggestions = null;
  },
  commitToken: mutationUtilities.set(STATEVAR_TOKEN),
  commitSuggestions: mutationUtilities.set(STATEVAR_SUGGESTIONS),
  commitDetailedResults: mutationUtilities.set(STATEVAR_DETAILED_RESULTS),
  loadingSuggestions: mutationUtilities.set(STATEVAR_LOADING_SUGGESTIONS),
  LastEnteredText: mutationUtilities.set(STATEVAR_LASTENTEREDTEXT),
  [MUTATION_SET_KEY]: mutationUtilities.set(STATEVAR_KEY),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
