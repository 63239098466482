import Vue from 'vue';
import VueRouter from 'vue-router';
import JourneyPlanner from '@/views/JourneyPlanner.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/journey-planner',
    name: 'JourneyPlanner',
    component: JourneyPlanner,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
