<template>
  <div>
    <waypoint-input
      :id="index === 0 ? 'origin-input' : 'destination-input'"
      :class="originAndDestinationClasses(index)"
      :input-id="waypoint.id"
      :key="waypoint.id"
      :placeholder="index === 0 ? 'Origin Address' : 'Destination Address'"
      v-for="(waypoint, index) in originAndDestination"
    />
    <waypoint-input
      class="mb-2 mt-3"
      :input-id="waypoint.id"
      is-delete-allowed
      :key="waypoint.id"
      :placeholder="`Stop address ${index+1}`"
      :is-allowed-to-add="index === waypoints.length - 1"
      v-for="(waypoint, index) in waypoints"
    />
    <div class="d-flex justify-content-end mb-2" v-if="canShowAddWaypointButton">
      <b-button
        @click="AddWaypointInput"
        variant="outline-primary"
        title="Add waypoint"
      >
        <b-icon-plus />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import {
  BButton,
  BIconPlus,
} from 'bootstrap-vue';

import WaypointInput from './WaypointInput.vue';

export default {
  components: {
    BButton,
    BIconPlus,
    WaypointInput,
  },
  computed: {
    ...mapGetters({
      WaypointInputs: 'journeyStore/WaypointInputs',
    }),
    canShowAddWaypointButton() {
      return this.originAndDestination
        .filter((od) => !!od.latitude && !!od.longitude).length === 2
        && this.waypoints.length === 0;
    },
    originAndDestination() {
      return [
        ...this.WaypointInputs.filter((_, i) => i === 0 || i === this.WaypointInputs.length - 1),
      ];
    },
    waypoints() {
      const waypoints = [];
      for (let i = 1; i < this.WaypointInputs.length - 1; i += 1) {
        waypoints.push(this.WaypointInputs[i]);
      }
      return waypoints;
    },
  },
  methods: {
    ...mapActions({
      GetRoute: 'journeyStore/GetRoute',
    }),
    ...mapMutations({
      AddWaypointInput: 'journeyStore/AddWaypointInput',
      SetWaypointInputs: 'journeyStore/SetWaypointInputs',
    }),
    inputPlaceholder(index) {
      if (index === 0) {
        return 'Origin Address';
      }
      if (index === this.WaypointInputs.length - 1) {
        return 'Destination Address';
      }
      return 'Stop address';
    },
    originAndDestinationClasses(index) {
      const classes = ['mb-2', 'px-0'];
      if (index === 1) {
        classes.push('mt-3');
      }
      return classes;
    },
  },
  name: 'Waypoints',
  watch: {
    WaypointInputs: {
      handler(newV, old) {
        // user deleted an input, don't auto add another one
        if (old.length > newV.length) {
          return;
        }
        // check if second last element i.e waypoint has already been populated
        const canAddNext = newV.length === 2 ? newV[newV.length - 1]?.description
          : newV[newV.length - 2]?.description;
        if (newV.length < 5 && canAddNext) {
          this.AddWaypointInput();
        }
      },
      deep: true,
    },
  },
};
</script>
