<template>
  <b-container fluid>
    <b-row>
      <b-col md="4" class="p-3 text-left">
        <h1>Optimal Journey Planner</h1>
      </b-col>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button
          @click="shareViaWebShare"
          title="Share This App"
          variant="outline-secondary"
        >
          <b-icon-share/>
        </b-button>
        <b-modal
          id="modal-1"
          title="Share This App"
          ref="fallback-modal"
          @ok="handleOk"
        >
          <b-form-textarea
            id="share-fallback"
            ref="toCopy"
            v-model="shareUrl"
            rows="1"
            max-rows="6"
          ></b-form-textarea>
          <template #modal-footer="{ ok }">
            <b-button size="sm" variant="primary" @click="ok()">
              Copy URL to Clipboard
            </b-button>
          </template>
        </b-modal>
        <b-button @click="startTour" variant="outline-secondary">
          Start Tour
        </b-button>
      </b-col>
    </b-row>
    <contact-expert/>
    <v-tour
      class="journeyPlannerTour"
      name="journeyPlannerTour"
      :options="tourOptions"
      :steps="journeyPlannerTourSteps"
    />
    <b-row>
      <!--Column 1 -->
      <b-col class="mb-3" lg="4">
        <waypoints />
        <div class="col-12 px-0">
          <b-button
            block
            @click="getRouteClicked"
            :disabled="IsLoading || !canGetRoute"
            variant="primary"
          >
            Journey on a map
            <b-spinner small v-if="IsLoading" />
            <span class="sr-only">Loading...</span>
          </b-button>
        </div>
        <b-alert
          class="col-12 mt-3"
          dismissible
          show
          variant="danger"
          v-if="ErrorMessage"
        >
          {{ ErrorMessage }}
        </b-alert>
      </b-col>
      <!--Column 2 -->
      <b-col class="mb-3" lg="4">
        <b-card
          id="map-journey-overview-toggle"
          no-body
        >
        <b-tabs card>
          <b-tab
            active
            id="map-area-tab"
            title="Map"
          >
            <leaflet-map :waypoints="Waypoints"/>
          </b-tab>
          <b-tab title="Journey overview">
            <journey-overview />
          </b-tab>
        </b-tabs>
      </b-card>
      </b-col>
      <!--Column 3 -->
      <b-col class="mb-5" lg="4">
        <InfomationSection />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BFormTextarea,
  BContainer,
  BIconShare,
  BRow,
  BTab,
  BTabs,
} from 'bootstrap-vue';

import ContactExpert from '@/components/ContactExpert.vue';
import JourneyOverview from '@/components/JourneyOverview.vue';
import LeafletMap from '@/components/LeafletMap.vue';
import InfomationSection from '@/components/InfomationSection.vue';
import Waypoints from '@/components/Waypoints.vue';

export default {
  components: {
    BAlert,
    BButton,
    BCard,
    BCol,
    BFormTextarea,
    BContainer,
    BIconShare,
    BRow,
    BTab,
    BTabs,
    ContactExpert,
    LeafletMap,
    InfomationSection,
    Waypoints,
    JourneyOverview,
  },
  computed: {
    ...mapGetters({
      ErrorMessage: 'journeyStore/ErrorMessage',
      IsLoading: 'journeyStore/IsLoading',
      SelectedAvoidOption: 'journeyStore/SelectedAvoidOption',
      SelectedOptimisationParam: 'journeyStore/SelectedOptimisationParam',
      WaypointInputs: 'journeyStore/WaypointInputs',
      ValidWaypoints: 'journeyStore/ValidWaypoints',
    }),
    canAddWayPointInput() {
      return this.WaypointInputs.length < 5;
    },
    canGetRoute() {
      return this.ValidWaypoints.length >= 2;
    },
    journeyPlannerTourSteps() {
      return [
        {
          content:
            'Enter the address your journey will start on.',
          header: {
            title: 'Origin Address Search',
          },
          params: {
            placement: 'auto',
          },
          target: '#origin-input',
        },
        {
          content:
            'Enter the address your journey will end on.',
          header: {
            title: 'Destination Address Search',
          },
          params: {
            placement: 'auto',
          },
          target: '#destination-input',
        },
        {
          content:
            'Press to view your journey on a map.',
          header: {
            title: 'View my journey on the map',
          },
          params: {
            placement: 'auto',
          },
          target: '#map-journey-overview-toggle ul li:first-child',
        },
        {
          content: 'View the journey on the map.',
          header: {
            title: 'Map Area',
          },
          params: {
            placement: 'auto',
          },
          target: '.map-container',
        },
        {
          content:
            'Press to view your Journey overview.',
          header: {
            title: 'Journey overview',
          },
          params: {
            placement: 'auto',
          },
          target: '#map-journey-overview-toggle ul li:nth-child(2)',
        },
      ];
    },
    shareUrl() {
      const { origin, pathname } = window.location;
      return `${origin}${pathname}`;
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour (Esc)',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
        useKeyboardNavigation: true,
      };
    },
  },
  destroyed() {
    window.removeEventListener('iris:share-capability');
    window.removeEventListener('iris:start-tour');
  },
  methods: {
    ...mapActions({
      GetRoute: 'journeyStore/GetRoute',
    }),
    ...mapMutations({
      SetWaypointInputs: 'journeyStore/SetWaypointInputs',
    }),
    getRouteClicked() {
      const listOfPoints = this.ValidWaypoints.map((point) => ({
        latitude: point.latitude,
        longitude: point.longitude,
      }));
      this.GetRoute(listOfPoints);
    },
    handleOk() {
      const Url = this.$refs.toCopy;
      Url.innerHTML = this.shareUrl;
      Url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    isTourOn() {
      const { tour } = this.$route.query;
      if (!tour) {
        return false;
      }
      return /^true$/i.test(tour.toLowerCase());
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            url: this.shareUrl,
          })
          .then()
          .catch(() => {
            // Handle the error!
          });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
    startTour() {
      this.$tours.journeyPlannerTour.start();
    },
    tourConfigure() {
      const hasSeenTour = JSON.parse(
        localStorage.getItem('hasSeenjourneyPlannerTour'),
      );
      console.log(hasSeenTour);
      if (!hasSeenTour || this.isTourOn()) {
        this.startTour();
        localStorage.setItem('hasSeenjourneyPlannerTour', true);
      }
    },
  },
  mounted() {
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    this.tourConfigure();
    const { query } = this.$route.query;
    if (query) {
      this.searchText = query;
    }
  },
  name: 'JourneyPlanner',
  watch: {
    SelectedOptimisationParam(newV) {
      if (!newV || !this.canGetRoute) {
        return;
      }
      this.getRouteClicked();
    },
    SelectedAvoidOption() {
      if (!this.canGetRoute) {
        return;
      }
      this.getRouteClicked();
    },
  },
};
</script>

<style src="vue-tour/dist/vue-tour.css"></style>
<style scoped>
.overlay {
  position: absolute;
  width: 100%;
  z-index: 900;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
.journeyPlannerTour {
  z-index: 500;
}
#share-fallback {
  pointer-events: none;
  background-color: lightgrey;
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none
  }
}
</style>
