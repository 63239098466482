<template>
  <div
    class="map-container"
    :id="leafletContainerId"
  >
    Loading Map...
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet/dist/leaflet';
import EventBus from '@/eventBus';

const originIcon = require('@/assets/icons/origin.png');
const destinationIcon = require('@/assets/icons/destination.png');

const waypointFiles = require.context('@/assets/icons/waypoints', false, /\.png$/);
const wayPointIcons = {};
waypointFiles.keys().forEach((fileName, index) => {
  wayPointIcons[index] = waypointFiles(fileName);
});

export default {
  computed: {
    ...mapGetters({
      RouteData: 'journeyStore/Data',
      WaypointInputs: 'journeyStore/WaypointInputs',
    }),
    center: () => [-28.4792625, 24.6727135],
    countryBoundingBox: () => [[-34.8191663551, 16.3449768409], [-22.0913127581, 32.830120477]],
    leafletContainerId: () => 'leaflet-container',
    selectedBaseType: () => 'vector',
  },
  data() {
    return {
      agMapLoaded: false,
      leafletMap: null,
      latLngBounds: null,
      markersLayer: [],
      routeLayer: null,
    };
  },
  methods: {
    checkIfAgMapLoaded() {
      this.agMapLoaded = !!window.AfriGIS;
      if (this.agMapLoaded) {
        return;
      }
      setTimeout(() => {
        this.checkIfAgMapLoaded();
      }, 500);
    },
    clearRoute() {
      if (!this.routeLayer || !this.leafletMap.hasLayer(this.routeLayer)) {
        return;
      }
      this.leafletMap.removeLayer(this.routeLayer);

      this.routeLayer = null;
    },
    clearMarkers() {
      this.markersLayer.forEach((m) => {
        this.leafletMap.removeLayer(m);
      });
      this.markersLayer = [];
    },
    drawRoute(newV) {
      this.routeLayer = L.polyline(newV, {
        color: '#003a63', weight: 5, smoothFactor: 1,
      }).addTo(this.leafletMap);
      this.leafletMap.fitBounds(this.routeLayer.getBounds());
    },
    drawMarkers(waypoints) {
      waypoints.forEach((point, index) => {
        const marker = L.marker([point.latitude, point.longitude], {
          icon: L.icon({
            shadowUrl: null,
            iconAnchor: new L.Point(16, 32),
            iconSize: new L.Point(32, 32),
            iconUrl: this.getIconUrl(index, waypoints),
          }),
        });
        this.leafletMap.addLayer(marker);
        this.markersLayer.push(marker);
      });
    },
    getIconUrl(index, waypoints) {
      if (index === 0) {
        return originIcon;
      }
      if (index === waypoints.length - 1) {
        return destinationIcon;
      }
      return wayPointIcons[index - 1];
    },
    initMap() {
      if (this.leafletMap) {
        return;
      }

      this.leafletMap = window.AfriGIS.map(this.leafletContainerId, {
        center: L.latLng(this.center),
        authkey: process.env.VUE_APP_AG_LEAFLET_API_KEY,
        mapType: this.selectedBaseType,
        maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
        maxBoundViscosity: 0,
        maxZoom: 18,
        minZoom: 3,
        zoom: 5,
        zoomControl: true,
      });
      this.leafletMap.fitBounds(this.countryBoundingBox);
      this.leafletMap.zoomControl.setPosition('bottomright');
      setTimeout(() => {
        // wait for the map to fully render
        this.leafletMap.invalidateSize();
      }, 3000);
    },
  },
  mounted() {
    this.checkIfAgMapLoaded();
    EventBus.$on('clear-map', () => {
      this.clearRoute();
    });
  },
  name: 'LeafletMap',
  watch: {
    agMapLoaded(newV) {
      if (!newV) {
        return;
      }
      this.initMap();
    },
    RouteData(newV) {
      if (!newV || !newV.routePoints || !newV.waypoints) {
        return;
      }
      const { routePoints, waypoints } = newV;
      this.clearRoute();
      this.drawRoute(newV.routePoints);
      this.clearMarkers();
      /**
       * Should proxy not return waypoints/via via ponits, i.e user only provided
       * source and destination,
       * Use the the first and last routes points for marker coordinates.
       */
      const points = waypoints.length > 0 ? waypoints
        : routePoints.filter((_, i) => i === 0 || i === routePoints.length - 1);

      const p = points.map(([a, b]) => ({
        latitude: a,
        longitude: b,
      }));
      this.drawMarkers(p);
    },
    WaypointInputs: {
      handler(newV) {
        this.clearMarkers();
        const waypoints = newV.filter((w) => !!w.latitude && !!w.longitude);
        this.drawMarkers(waypoints);
      },
      deep: true,
    },
  },
};
</script>

<style>
.leaflet-bar a{
    background-color: var(--primary) !important;
    color: #fff !important;
  }
.map-container {
    position: relative;
    width: 100%;
    height: 55vh;
  }
.leaflet-interactive {
    cursor: grab;
  }
.leaflet-marker-icon {
    cursor: pointer;
  }
</style>
