import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';
import { v4 as uuidv4 } from 'uuid';
import { createAwsAxiosInstance, fetchRoutePointsAndWaypoints } from '@afrigis/aws-navigation-routes';

const MUTATION_SET_DATA = 'SetData';
const MUTATION_SET_IS_LOADING = 'SetIsLoading';
const MUTATION_SET_ERROR_MESSAGE = 'SetErrorMessage';
const MUTATION_SET_WAYPOINT_INPUTS = 'SetWaypointInputs';
const MUTATION_SET_SELECTED_OPTIMISATION_PARAM = 'SetSelectedOptimisationParam';
const MUTATION_SET_SELECTED_AVOID_OPTION = 'SetSelectedAvoidOption';

const STATEVAR_AVOID_OPTIONS = 'AvoidOptions';
const STATEVAR_SELECTED_AVOID_OPTION = 'SelectedAvoidOption';
const STATEVAR_DATA = 'Data';
const STATEVAR_OPTIMISATION_PARAMS = 'OptimisationsParams';
const STATEVAR_SELECTED_OPTIMISATION_PARAM = 'SelectedOptimisationParam';
const STATEVAR_IS_LOADING = 'IsLoading';
const STATEVAR_ERROR_MESSAGE = 'ErrorMessage';
const STATEVAR_WAYPOINT_INPUTS = 'WaypointInputs';
const STATEVAR_VALID_WAYPOINT = 'ValidWaypoints';

const actions = {
  GetRoute: async (context, listOfPoints) => {
    try {
      if (!listOfPoints) {
        throw new Error('Parameter "listOfPoints" is required');
      }
      context.commit(MUTATION_SET_IS_LOADING, true);
      const { token, key } = context.rootState.addressSearchStore;
      const axiosInstance = await createAwsAxiosInstance(token, key);
      const requestOptions = {
        axiosInstance,
        listOfPoints,
        avoidType: context.state[STATEVAR_SELECTED_AVOID_OPTION],
        optimisationParam: context.state[STATEVAR_SELECTED_OPTIMISATION_PARAM],
      };
      const result = await fetchRoutePointsAndWaypoints(requestOptions);
      context.commit(MUTATION_SET_DATA, result);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      context.commit(MUTATION_SET_ERROR_MESSAGE, errorDescription);
    } finally {
      context.commit(MUTATION_SET_IS_LOADING, false);
    }
  },
};

const getters = {
  [STATEVAR_AVOID_OPTIONS]: GettersUtility.get(STATEVAR_AVOID_OPTIONS),
  [STATEVAR_DATA]: GettersUtility.get(STATEVAR_DATA),
  [STATEVAR_IS_LOADING]: GettersUtility.get(STATEVAR_IS_LOADING),
  [STATEVAR_ERROR_MESSAGE]: GettersUtility.get(STATEVAR_ERROR_MESSAGE),
  [STATEVAR_OPTIMISATION_PARAMS]: GettersUtility.get(STATEVAR_OPTIMISATION_PARAMS),
  [STATEVAR_SELECTED_OPTIMISATION_PARAM]: GettersUtility.get(STATEVAR_SELECTED_OPTIMISATION_PARAM),
  [STATEVAR_SELECTED_AVOID_OPTION]: GettersUtility.get(STATEVAR_SELECTED_AVOID_OPTION),
  [STATEVAR_WAYPOINT_INPUTS]: GettersUtility.get(STATEVAR_WAYPOINT_INPUTS),
  [STATEVAR_VALID_WAYPOINT]: (stateP) => stateP[STATEVAR_WAYPOINT_INPUTS]
    .filter((w) => !!w.latitude && !!w.longitude),
};

const mutations = {
  [MUTATION_SET_SELECTED_AVOID_OPTION]: MutationsUtility.set(STATEVAR_SELECTED_AVOID_OPTION),
  [MUTATION_SET_DATA]: MutationsUtility.set(STATEVAR_DATA),
  [MUTATION_SET_IS_LOADING]: MutationsUtility.set(STATEVAR_IS_LOADING),
  [MUTATION_SET_ERROR_MESSAGE]: MutationsUtility.set(STATEVAR_ERROR_MESSAGE),
  [MUTATION_SET_SELECTED_OPTIMISATION_PARAM]: MutationsUtility
    .set(STATEVAR_SELECTED_OPTIMISATION_PARAM),
  [MUTATION_SET_WAYPOINT_INPUTS]: MutationsUtility.set(STATEVAR_WAYPOINT_INPUTS),
  AddWaypointInput(stateP) {
    const localStateP = stateP;
    const localWaypoints = [...localStateP[STATEVAR_WAYPOINT_INPUTS]];
    const newWaypoint = {
      id: uuidv4(),
      latitude: null,
      longitude: null,
    };
    localWaypoints.splice(localWaypoints.length - 1, 0, newWaypoint);
    localStateP[STATEVAR_WAYPOINT_INPUTS] = localWaypoints;
  },
};

const state = {
  [STATEVAR_DATA]: [],
  [STATEVAR_IS_LOADING]: false,
  [STATEVAR_ERROR_MESSAGE]: null,
  [STATEVAR_AVOID_OPTIONS]: [
    {
      text: 'None',
      value: null,
    },
    {
      text: 'Highway',
      value: 'HIGHWAY',
    },
    {
      text: 'Main Roads',
      value: 'MAIN_ROADS',
    },
    {
      text: 'Street',
      value: 'STREET',
    },
    {
      text: 'Toll Road',
      value: 'TOLLROAD',
    },
  ],
  [STATEVAR_SELECTED_AVOID_OPTION]: null,
  [STATEVAR_OPTIMISATION_PARAMS]: [
    {
      text: 'Quickest Travel Time',
      value: 'TRAVELTIME',
    },
    {
      text: 'Shortest Distance',
      value: 'LENGTH',
    },
    {
      text: 'Least Traffic',
      value: 'TRAFFIC',
    },
  ],
  [STATEVAR_SELECTED_OPTIMISATION_PARAM]: 'TRAVELTIME',
  [STATEVAR_WAYPOINT_INPUTS]: [
    {
      id: uuidv4(),
      latitude: null,
      longitude: null,
    },
    {
      id: uuidv4(),
      latitude: null,
      longitude: null,
    },
  ],
};

export default {
  actions,
  getters,
  mutations,
  state,
};
